<template>
  <div>
    <div>
      <v-btn
        v-clipboard:copy="message"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
        type="button"
        block
        elevation="5"
      >
        <h4>
          {{ btnText }}
          <v-icon>
            mdi-content-copy
          </v-icon>
        </h4>
      </v-btn>
    </div>
    <v-data-iterator
      :items="items"
      sort-by="id_str"
      :search="search"
      :sort-desc="sortDesc"
      :footer-props="{ 'items-per-page-options': [20, 30, 40] }"
      :items-per-page="20"
      :page="page"
      :options="options"
      @options="optionsSave"
      @update:page="scrollTop"
    >
      <template v-slot:header>
        <v-toolbar
          dark
          color="gray darken-3"
          class="mb-1"
        >
          <v-text-field
            v-model="search"
            clearable
            flat
            solo-inverted
            hide-details
            prepend-inner-icon="mdi-magnify"
            :label="$t('message.search_by_artwork')"
          />
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-spacer />
            <v-btn-toggle
              v-model="sortDesc"
              mandatory
            >
              <v-btn
                large
                depressed
                color="gray"
                :value="false"
              >
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn
                large
                depressed
                color="gray"
                :value="true"
              >
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </v-btn-toggle>
          </template>
        </v-toolbar>
        <v-chip-group>
          <v-chip
            v-for="(house_size, index) in SizeArray"
            :key="index"
            dark
            color="#e91e63"
            @click="updateItems(house_size)"
          >
            {{ house_size }}
          </v-chip>
        </v-chip-group>
      </template>

      <template v-slot:default="props">
        <!-- <v-select
        v-model="selectedId"
        item-text="user.name"
        item-value="user.name"
        :items="items"
        label="ユーザーで絞る"
        v-on:change="changeUser(selectedId)"
      /> -->
        <!-- v-touch="{
            left: () => $router.go(1),
            right: () => $router.push(-1)
          }" -->
        <v-row
          dense
        >
          <v-col
            v-for="item in props.items"
            :key="item.id_str"
            cols="12"
            sm="6"
            md="4"
            lg="2"
          >
            <v-container
              class="pa-2 max-width"
            >
              <v-badge
                v-if="item.update_date >= targetDate"
                offset-x="20"
                offset-y="28"
                left
                dot
              />
              <!-- @TweetError="notShow(index)" -->
              <tweet
                :id="item.id_str"
                error-message=" "
                :options="{ align: 'center' }"
                @TweetError="notShow(item)"
              >
                <spinner />
              </tweet>
              <v-chip
                v-if="item.aetheryteId"
                class="pa-3 mx-1"
              >
                {{ aetheryte(item.aetheryteId) }}
              </v-chip>
              <v-chip
                v-if="item.size"
                class="pa-3 mx-1"
              >
                {{ item.size }}
              </v-chip>
            </v-container>
          </v-col>

          <!-- <ads /> -->
        </v-row>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
  import { Tweet } from 'vue-tweet-embed'
  import axios from 'axios'
  import Spinner from 'vue-simple-spinner'
  // import Ads from './Ads'
  export default {
    name: 'ArtworkView',
    components: {
      Tweet,
      Spinner,
      // Ads,
    },
    metaInfo: {
      title: 'Artwork',
      titleTemplate: '%s | FFXIV Housing Eden',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'keywords', content: 'ff14 ハウジング,ハウジング ff14,ff14 housing,housing ff14,ff14ハウジング,ff14housing,ハウジング,ハウジングエデン,ff14,ファイナルファンタジー,housing,ffxiv,final fantasy,Housing Eden,housing eden' },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { property: 'og:url', content: 'https://e-d-e-n.site' },
        { property: 'og:title', content: 'FFXIV Housing Eden' },
        { name: 'description', content: 'FFXIV Housing Edenはファイナルファンタジー14の最新ハウジング情報を世界レベルでお届けするサイトです。今話題のツイートはもちろん、バズツイート、ハウジンガー名鑑、創作家具レシピまで網羅しています。' },
        { property: 'og:image', content: 'https://e-d-e-n.site/twitter_card.jpg?3' },
      ],
    },
    data: function () {
      return {
        items: [],
        rawItems: [],
        items_num: 0,
        sortDesc: true,
        search: '',
        message: '',
        btnText: '',
        options: {},
        page: 1,
        targetDate: '',
        loading: true,
        indexs: [],
        json_items: [],
        SizeArray: ['All', 'S', 'M', 'L', 'apartment', 'FC room'],
      }
    },

    // watch: {
    //   default: function (val, oldVal) {
    //     console.log(val)
    //     if (val === ' ') {
    //       this.vshow = false
    //     } else { this.vshow = false }

    //   },
    //   deep: true,
    // },
    created: async function () {
      this.btnText = this.$t('message.copy_the_url')
      // 日付をYYYY-MM-DDの書式で返すメソッド
      function formatDate (dt) {
        var y = dt.getFullYear()
        var m = ('00' + (dt.getMonth() + 1)).slice(-2)
        var d = ('00' + dt.getDate()).slice(-2)
        return (y + '-' + m + '-' + d)
      }
      this.targetDate = new Date()
      this.targetDate = formatDate(new Date(this.targetDate.getFullYear(), this.targetDate.getMonth(), this.targetDate.getDate() - 2))
      // console.log(this.targetDate)
      // if (!this.$store.artworkItems) {
      await axios.get(`../../../artwork/${this.$route.params.id}.json?timestamp=${new Date().getTime()}`).then(res => {
        this.rawItems = res.data
        res.data.forEach(data => {
          this.items.push(data)
        })
      })
      // }
      await axios.get(`../../../aetheryte.json?timestamp=${new Date().getTime()}`).then(res => {
        res.data.forEach(data => {
          this.json_items.push(data)
        })
      })

      // if (!this.$store.artworkItems) {
      //   await axios.get(`../../../artwork.json?timestamp=${new Date().getTime()}`).then(res => {
      //     res.data.forEach(data => {
      //       if (data.user_id_str === this.$route.params.id) {
      //         this.items.push(data)
      //       }
      //     })
      //   })
      // } else {
      //   await this.$store.artworkItems.forEach(data => {
      //     if (data.user_id_str === this.$route.params.id) {
      //       this.items.push(data)
      //     }
      //   })
      // }

      // console.log(this.items.length)
      this.items_num = this.items.length

      this.message = 'https://e-d-e-n.site/#' + this.$route.path

      if (this.$store.artworkOptions) {
        this.options = this.$store.artworkOptions
      }
      // console.log(this.$store.page)
      this.page = this.$store.artworkPage
      // console.log(this.$store.page)
    },
    // mounted () {
    //   this.$ga.page(`/artwork/${this.$route.params.id}`)
    // },
    // mounted: function () {
    //   this.$nextTick(function () {
    //     // ビュー全体がレンダリングされた後にのみ実行されるコード
    //     this.indexs.forEach(function (element, index, array) {
    //       console.log(index)
    //       this.items.splice(element - index, 1)
    //     })
    //   })
    // },
    methods: {
      aetheryte: function (text) {
        for (var key of Object.keys(this.json_items)) {
          // 連想配列のキーと配列の値が一致するか検索
          // console.log(this.json_items[key].AreaNameEn)
          if (this.json_items[key].AetheryteId === text) {
            switch (this.$i18n.locale) {
              case 'ja':
                return this.json_items[key].AetheryteJp
              case 'en':
                return this.json_items[key].AetheryteEn
              case 'ko':
                return this.json_items[key].AetheryteKo
              case 'zh-CN':
                return this.json_items[key].AetheryteCHS
              case 'zh-TW':
                return this.json_items[key].AetheryteCHT
            }
          }
        }
      },
      size: function (text) {
        for (var key of Object.keys(this.json_items)) {
          // 連想配列のキーと配列の値が一致するか検索
          // console.log(this.json_items[key].AreaNameEn)
          if (this.json_items[key].AetheryteId === text) {
            return this.json_items[key].Size
          }
        }
      },
      scrollTop: function () {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
        this.$store.artworkPage = event
      },
      // 削除済みツイートの排除
      notShow: function (item) {
        // 指定されたindexの要素を1つ削除します。
        // this.indexs.push(index)
        // setTimeout(() => {
        // console.log('item:' + item)
        // console.log('items_num:' + this.items_num)
        // console.log('items.length:' + this.items.length)
        // this.items.splice(index - (this.items_num - this.items.length - 1), 1)
        // 要素を削除する
        // this.items.some(function (v, i, item) {
        //   if (v === item) this.items.splice(i, 1)
        // })
        var result = this.items.filter(function (i) {
          return i !== item
        })
        this.items = result
        // }, 1000)

        // console.log(index)
        // this.indexs.push(index)
        // console.log(this.indexs)
      },
      onCopy: function (e) {
        this.btnText = 'コピーしました'
        console.log('You just copied: ' + e.text)
      },
      onError: function (e) {
        this.btnText = 'エラーが発生しました'
        console.log('Failed to copy texts')
      },
      optionsSave: function (event) {
        // console.log(event)
        this.$store.artworkOptions = event
      },
      updateItems (houseSize) {
        if (houseSize !== 'All') {
          this.items = this.rawItems.filter(item => item.size === houseSize)
        } else { this.items = this.rawItems }
      },
    },
  }
</script>
